import React, { Component } from 'react'
import { connect } from 'react-redux'

import { addUser } from './../store/actions/user'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { AppInstances } from './../utils/counterSdkInstance'

import './Login.css'

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: user => dispatch(addUser(user))
  }
}

class Login extends Component {
  state = {
    username: '',
    password: '',
    showPassword: false
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.login()
    }
  }

  isReady = (username, password) => {
    return new Promise(async function(resolve, reject) {
      let countr = await AppInstances.getCountrSdk()

      countr.register(username, password).then(
        user => {
          localStorage.setItem('user', JSON.stringify(user))

          if (!localStorage.getItem('access_token')) {
            countr.refresh()
          }
          countr.once('registered', token => {
            localStorage.setItem('token_type', token.token_type)
            localStorage.setItem('access_token', token.access_token)
            localStorage.setItem('refresh_token', token.refresh_token)
            countr.setToken({
              access_token: token.access_token,
              refresh_token: token.refresh_token
            })
          })

          return resolve(user)
        },
        error => {
          return reject(error)
        }
      )
    })
  }

  login = () => {
    // this.props.loadingTrue()
    const { username, password } = { ...this.state }

    this.isReady(username, password).then(
      user => {
        console.log('​login -> user', user)
        this.props.addUser(user)
        this.props.history.history.push({
          pathname: '/root'
        })
      },
      error => {
        // this.props.loadingFalse()
      }
    )
  }

  componentDidMount() {}

  render() {
    return (
      <div className="login">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="username"
              label="Username"
              type="email"
              className="login-input-field"
              value={this.state.username}
              onChange={this.handleChange('username')}
              onKeyPress={this.handleKeyPress}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              label="Password"
              type="password"
              className="login-input-field"
              value={this.state.password}
              onChange={this.handleChange('password')}
              onKeyPress={this.handleKeyPress}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="raised" color="primary" className="login-btn" onClick={this.login}>
              Login
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const LoginConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
export default LoginConnected
