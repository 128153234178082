import React, { Component } from 'react'

import './Marker.css'

class Marker extends Component {
  state = {}

  testClick = () => {
    console.log(this.props.text)
  }

  render() {
    return (
      <div onClick={this.testClick}>
        <div className="pin bounce">
          <b className="price">{this.props.text}</b>
        </div>
        <div className="pulse" />
      </div>
    )
  }
}

export default Marker
