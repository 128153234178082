import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from '../utils/counterSdkInstance'

import GoogleMapReact from 'google-map-react'

import Marker from './Marker'
import LastTransaction from './LastTransaction'

import './Root.css'

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

class Root extends Component {
  state = {
    transactionsList: [],
    timer: 0
  }

  static defaultProps = {
    center: {
      lat: 52.32948,
      lng: 4.59123
    },
    zoom: 11
  }

  transactionListenerRegister = () => {
    AppInstances.getCountrSdk().then(socket => {
      const user = this.props.user.user
      socket.ws.on(`a${user._id}:transaction.created`, message => {
        console.log('--- TRANSACTION CREATED ---')
        console.log(message.transaction)
        this.addTransactionMarker(message)
      })
      socket.ws.on(`m${user._id}:transaction.created`, message => {
        console.log('--- TRANSACTION CREATED ---')
        console.log(message.transaction)
        this.addTransactionMarker(message)
      })
    })
  }

  addTransactionMarker = transaction => {
    console.log(JSON.stringify(transaction))

    if (transaction.hasOwnProperty('location')) {
      let list = JSON.parse(JSON.stringify(this.state.transactionsList))
      list.push(transaction)
      this.setState({ transactionsList: list, timer: 0 }, () => {
        setTimeout(() => {
          this.removeTransactionMarker(transaction.transaction)
        }, 60000)
      })
    }
  }

  removeTransactionMarker = id => {
    let list = JSON.parse(JSON.stringify(this.state.transactionsList))
    const index = list.findIndex(t => t.transaction === id)
    list.splice(index, 1)
    this.setState({ transactionsList: list })
  }

  initLastTransactionTimer = () => {
    setInterval(() => {
      this.setState({ timer: this.state.timer + 1 })
    }, 1000)
  }

  loadLastTransactions = () => {
    AppInstances.getCountrSdk().then(socket => {
      socket.transactions.read({ sort: '-created_at' }).then(
        transactions => {
          transactions.forEach(transaction => {
            setTimeout(() => this.addTransactionMarker(transaction), 500)
          })
        },
        error => {
          console.log(error)
          this.props.history.history.push({
            pathname: '/'
          })
        }
      )
    })
  }

  componentDidMount = () => {
    if (Object.keys(this.props.user.user).length === 0) {
      this.props.history.history.push({
        pathname: '/'
      })
    } else {
      this.transactionListenerRegister()
      this.initLastTransactionTimer()
    }
  }

  render() {
    return (
      <div className="root">
        <LastTransaction timer={this.state.timer} />
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAil2NLnr4BGZFWXgs8jrXBI8bNLzX-vLM' }}
          // bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          {this.state.transactionsList.map(transaction => (
            <Marker
              key={transaction.transaction}
              lat={transaction.location.latitude}
              lng={transaction.location.longitude}
              text={`${transaction.currency.symbol}${transaction.paid.toFixed(2)}`}
            />
          ))}
        </GoogleMapReact>
      </div>
    )
  }
}

const RootConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Root)
export default RootConnected
