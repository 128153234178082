import { createStore, combineReducers } from 'redux'
import userReducer from './reducers/user'

const rootReducer = combineReducers({
  user: userReducer
})

const configureStore = () => {
  return createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
}

export default configureStore
