import React, { Component } from 'react'
import { connect } from 'react-redux'

import './LastTransaction.css'

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

class LastTransaction extends Component {
  state = {}

  componentDidMount = () => {}

  render() {
    return (
      <div className="last-transaction">
        {this.props.timer < 60 ? (
          <div>Last transaction {this.props.timer} seconds ago.</div>
        ) : (
          <div>Last transaction {(this.props.timer / 60).toFixed(0)} minutes ago.</div>
        )}
      </div>
    )
  }
}

const LastTransactionConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(LastTransaction)
export default LastTransactionConnected
