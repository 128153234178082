import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import Login from './components/Login'
import Root from './components/Root'

import countrLogo from './assets/countr_white.png'
import './App.css'

class App extends Component {
  render() {
    return (
      <div className="countr-app">
        <header className="countr-app-header">
          <img src={countrLogo} className="countr-app-logo" alt="logo" />
        </header>
        <div className="countr-app-root">
          <Switch>
            <Route exact path="/" render={history => <Login history={history} />} />
            <Route exact path="/root" render={history => <Root history={history} />} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(App)
